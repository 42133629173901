import Swiper from "../../../dependencies/node_modules/swiper/dist/js/swiper.min"
import TweenMax from '../../../dependencies/node_modules/gsap/src/minified/TweenMax.min'

class Home {
    constructor() {
        this.slider = document.getElementById("slideshow");
        this.sliderCounter = document.getElementById("slideshow-counter");
        this.interleaveOffset = 0.75;
    }

    init() {
        this.initSlideShow();
    }

    initSlideShow() {
        const that = this;
        new Swiper(this.slider, {
            loop: true,
            direction: "vertical",
            speed: 800,
            grabCursor: false,
            watchSlidesProgress: true,
            mousewheelControl: true,
            mousewheel: true,
            pagination: {
                el: this.sliderCounter,
                clickable: true
            },
            on: {
                progress: function() {
                    let swiper = this;

                    for (let i = 0; i < swiper.slides.length; i++) {
                        let slideProgress = swiper.slides[i].progress;
                        let innerOffset = swiper.height * that.interleaveOffset;
                        let innerTranslate = slideProgress * innerOffset;

                        TweenMax.set(swiper.slides[i].querySelector(".slide-inner"), {
                            y: innerTranslate,
                        });
                    }
                },
                touchStart: function() {
                    let swiper = this;
                    for (let i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = "";
                    }
                },
                setTransition: function(speed) {
                    let swiper = this;
                    for (let i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        swiper.slides[i].querySelector(".slide-inner").style.transition =
                            speed + "ms";
                    }
                }
            }
        });
    }
}

export default function renderHomePage() {
    (new Home()).init();
}
