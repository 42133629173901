import {$, $jqHtml, Pages} from "./constants";
import renderGallery from "./pages/gallery";
import loading from "./utils/loading";
import './menu';
import renderHomePage from "./pages/home";

$(() => {
    loading(1000);

    //Render page specific calls
    if($jqHtml.hasClass(Pages.HOME)){
        renderHomePage();
    }
    if($jqHtml.hasClass(Pages.GALLERY)){
        renderGallery();
    }
});
