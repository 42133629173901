import imagesLoaded from '../../../dependencies/node_modules/imagesloaded/imagesloaded.pkgd.min'
import Masonry from '../../../dependencies/node_modules/masonry-layout/dist/masonry.pkgd.min'
import {GridLoaderFx, GridFxTypes} from '../scripts/GridLoaderFx';
import {GalleryFx} from "../scripts/GalleryFx";

class Gallery {
    constructor() {
        this.body = document.body;
        this.grids = [].slice.call(document.querySelectorAll('.grid'));
        this.masonry = [];
        this.currentGrid = 0;

        // Switch grid radio buttons.
        this.switchGridCtrls = [].slice.call(document.querySelectorAll('.control__radio'));

        // Choose effect buttons.
        this.fxCtrls = [].slice.call(document.querySelectorAll('.control--effects > .control__btn'));

        // The GridLoaderFx instances.
        this.loaders = [];
        this.loadingTimeout = null;
        this.loading_window = document.getElementById('loading-window');
    }

    init() {
        const that = this;
        this.loading_window.classList.add('force-active');
        imagesLoaded(this.body, function () {
            // Initialize Masonry on each grid.
            that.grids.forEach(function (grid) {
                let m = new Masonry(grid, {
                    itemSelector: '.grid__item',
                    columnWidth: '.grid__sizer',
                    percentPosition: true,
                    transitionDuration: 0
                });
                that.masonry.push(m);
                // Hide the grid.
                grid.classList.add('grid--hidden');
                // Init GridLoaderFx.
                that.loaders.push(new GridLoaderFx(grid));
            });

            that.initTriggers();

            // Apply Load FX.
            that.grids[that.currentGrid].classList.add('grid--loading');
            that.grids[that.currentGrid].classList.remove('grid--hidden');
            that.applyGridFx(that, GridFxTypes.PresentationFade);

            // Remove loading class from body
            that.loading_window.classList.remove('force-active');
        });
        this.setupGalleryModals();
    }

    /**
     * Bind events
     */
    initTriggers() {
        console.log('event triggers initialized')
        //Triggers for the gallery go here
    }

    applyGridFx(that, fx) {
        // Simulate loading grid to show the effect.
        clearTimeout(that.loadingTimeout);
        that.grids[that.currentGrid].classList.add('grid--loading');

        that.loadingTimeout = setTimeout(function () {
            that.grids[that.currentGrid].classList.remove('grid--loading');
            // Apply effect.
            that.loaders[that.currentGrid]._render(fx);
        }, 500);
    }

    setupGalleryModals() {
        // transition end event names
        const transEndEventNames = { 'WebkitTransition': 'webkitTransitionEnd', 'MozTransition': 'transitionend', 'OTransition': 'oTransitionEnd', 'msTransition': 'MSTransitionEnd', 'transition': 'transitionend' };
        const transEndEventName = transEndEventNames[ 'transition' ];
        const onEndTransition = function( el, callback ) {
            const onEndCallbackFn = function( ev ) {
                if( ev.target !== this )
                    return;
                this.removeEventListener( transEndEventName, onEndCallbackFn );
                if( callback && typeof callback === 'function' ) { callback.call(this); }
            };
            el.addEventListener( transEndEventName, onEndCallbackFn );
        };

        new GalleryFx(document.querySelector('.grid'), {
            imgPosition : {
                x : -0.5,
                y : 1
            },
            onOpenItem : function(instance, item) {
                instance.items.forEach(function(el) {
                    if(item !== el) {
                        var delay = Math.floor(Math.random() * 50);
                        el.style.WebkitTransition = 'opacity .5s ' + delay + 'ms cubic-bezier(.7,0,.3,1), -webkit-transform .5s ' + delay + 'ms cubic-bezier(.7,0,.3,1)';
                        el.style.transition = 'opacity .5s ' + delay + 'ms cubic-bezier(.7,0,.3,1), transform .5s ' + delay + 'ms cubic-bezier(.7,0,.3,1)';
                        el.style.WebkitTransform = 'scale3d(0.1,0.1,1)';
                        el.style.transform = 'scale3d(0.1,0.1,1)';
                        el.style.opacity = 0;
                    }
                });
            },
            onCloseItem : function(instance, item) {
                instance.items.forEach(function (el) {
                    if (item !== el) {
                        el.style.WebkitTransition = 'opacity .4s, -webkit-transform .4s';
                        el.style.transition = 'opacity .4s, transform .4s';
                        el.style.WebkitTransform = 'scale3d(1,1,1)';
                        el.style.transform = 'scale3d(1,1,1)';
                        el.style.opacity = 1;

                        onEndTransition(el, function () {
                            el.style.transition = 'none';
                            el.style.WebkitTransform = 'none';
                        });
                    }
                });
            }
        });
    }
}

export default function renderGallery() {
    (new Gallery()).init();
}
